/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lightgallery@2.8.1/lightgallery.min.js
 * - /npm/lightgallery@2.8.1/plugins/zoom/lg-zoom.min.js
 * - /npm/lightgallery@2.8.1/plugins/fullscreen/lg-fullscreen.min.js
 * - /npm/lightgallery@2.8.1/plugins/video/lg-video.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
